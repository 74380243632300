<template>
  <header :id="id" class="header">
    <div class="header-content">
          <DxDropDownButton
            :split-button="true"
            :use-select-mode="false"
            :items="profileMenu"
            :text="fullname"
            :icon="avatar"
            display-expr="name"
            key-expr="id"
            @button-click="onButtonClick"
            @item-click="onItemClick"
          />
    </div>
  </header>
</template>

<script>
import { inject } from 'vue'
import DxDropDownButton from 'devextreme-vue/drop-down-button';
// import notify from 'devextreme/ui/notify';
import * as avatar from "@/assets/avatar_placeholder.svg";
import { UserService } from '@/services/user-service'
import { useRouter } from 'vue-router';

export default {
  components: { DxDropDownButton },
  props:{
    id: String
  },
  setup() {
    const user = inject('user');
    const notify = inject('notify');
    const router = useRouter();
    const fullname = `${user.firstName} ${user.lastName}`;

    const profileMenu = [
      { id: 1, name: 'Profile', icon: 'user' },
      { id: 3, name: 'Exit', icon: 'runner' }
    ]; 

    const onButtonClick = (e) => {
      notify(`Go to ${ e.component.option('text') }'s profile`, 'success', 600);
    };

    const onItemClick = (e) => {
      if(e.itemData.id === 3){
        logout();
      }
      // notify(e.itemData.name || e.itemData, 'success', 600);
    };

    const logout = () => {
      notify(`${fullname} is logout.`);
      UserService.logout();
      router.push("/login");
    };

    return { fullname, onButtonClick, onItemClick, logout, profileMenu, avatar, router };
  }
}
</script>

<style lang="scss" scoped>
.header{
  background-color: var(--primary-dark);
  padding: 10px 60px;
  z-index: 0;
  width: 100%;
  transition: all 0.3s ease;
  
  .header-content{
    display: flex;
    flex-direction: row-reverse;
  }
}
</style>