<template>
  <router-link :to="to" class="link" :class="{ active: isActive }">
    <font-awesome-icon :icon="iconAwesome" />
    <transition name="fade">
      <span class="link-title" v-if="!collapsed">
        <slot/>
      </span>  
    </transition>
  </router-link>
</template>

<script>
import { computed,  watch } from 'vue'
import { useRoute } from 'vue-router'
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

export default {
  props:{
    to: { type: String, required: true },
    icon: { type: String, required: true },
    type: { type: String, required: false, default: "fas" },
    collapsed: { type: Boolean, required: false }
  },
  setup(props){
    const route = useRoute();
    const isActive = computed(() => route.path === props.to );
    const sideBarCollapsed = computed(() => props.collapsed);
    const iconAwesome = findIconDefinition({ prefix: props.type, iconName: props.icon })

    watch(sideBarCollapsed, () => {});

    return { isActive, iconAwesome, sideBarCollapsed };
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  user-select: none;
  margin: 0.1em 0;
  padding-top: 0.4em;
  padding-right: 0.4em;
  padding-bottom: 0.4em;
  padding-left: 0.55em;
  border-radius: 0.25em;
  height: 2.5em;
  color: white;
  text-decoration: none;

  svg{
    margin-right: 10px;
  }
}
.link:hover {
  background-color: var(--sidebar-item-hover);
}
.link.active {
  background-color: var(--sidebar-item-active);
}
.link .icon {
  flex-shrink: 0;
  width: 25px;
  margin-right: 10px;
}
.link-title{
  padding-left: 0.6em;
}
</style>