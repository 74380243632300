<template>
  <nav :id="id" class="sidebar-nav" :style="{ width: sidebarWidth }">
    <h3>
      <span v-if="collapsed">
        <img src="@/assets/logo_transparent_without_title.png" :style="{ width: sidebarLogoWidth }"/>
        <div>L</div>
        <div>S</div>
      </span>
      <span v-else>
        <transition name="fade">
          <img src="@/assets/logo_transparent.png" :style="{ width: sidebarLogoWidth }"/>
        </transition>
          License Service
      </span>
    </h3>

    <div class="sidebar-links">
      <sidebar-link v-for="link in links" 
        :key="link.to" 
        :to="link.to"
        :icon="link.icon"
        :collapsed="collapsed"
      >
        {{link.caption}}
      </sidebar-link>
    </div>

    <div class="side-nav-footer">
      <span class="collapse-icon" :class="{'rotate-180': collapsed}" @click="toogleSidebar">
        <font-awesome-icon :icon="['fas', 'angle-double-left']" />
      </span>
    </div>
  </nav>
</template>  

<script>
const SIDEBAR_WIDTH = 10;
const SIDEBAR_WIDTH_COLLAPSED = 2.3;
const SIDEBAR_LOGO_WIDTH = 75;
const SIDEBAR_LOGO_WIDTH_COLLAPSED = 100;

import { ref, computed } from 'vue'
import SidebarLink from './SidebarLink'

export default {
  components: { SidebarLink },
  props: {
    id: String
  },
  setup(props, { emit }){
    const collapsed = ref(false);
    const sidebarLogoWidth = computed(
      () => `${collapsed.value ? SIDEBAR_LOGO_WIDTH_COLLAPSED : SIDEBAR_LOGO_WIDTH}%`
    );

    const sidebarWidth = computed(
      () => `${collapsed.value ? SIDEBAR_WIDTH_COLLAPSED : SIDEBAR_WIDTH }%`
    );

    const toogleSidebar = () => {
      collapsed.value = !collapsed.value;
      emit('toogle', { collapsed: collapsed.value } )
    };
    
    const links = ref([
      { to: "/", icon: "tachometer-alt", caption: "Dasboard", collapsed: collapsed.value },
      { to: "/licenses", icon: "file", caption: "Licenses", collapsed: collapsed.value  },
      { to: "/license-requests", icon: "arrow-circle-down", caption: "License Requests", collapsed: collapsed.value  },
      // { to: "/users", icon: "arrow-circle-down", caption: "Users", collapsed: collapsed.value  },
    ]); 

    return { collapsed, toogleSidebar, sidebarWidth, sidebarLogoWidth, links }
  },
  emits: ['toogle']
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.sidebar-nav{
  color: white;
  background-color: var(--sidebar-bg-color);

  float: left;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
  bottom: 0px;
  padding: 0.5em;

  transition: 0.3s ease;

  display: flex;
  flex-direction: column; 

  .sidebar-links{
    padding-top: 32px;
    display: flex;
    flex-direction: column; 
  }

  .side-nav-footer{
    position: absolute;
    bottom: 0px;
  }
}

.collapse-icon{
  position: absolute;
  bottom: 0px;

  padding: 0.75em;
  color: rgba(255,255,255,0.7);

  transition: 0.2s linear;
}

.rotate-180{
  transform: rotate(180deg);
  transition: 0.2s linear;
}
</style>

<style>
  :root {
    --sidebar-bg-color: var(--primary-dark);
    --sidebar-item-hover: var(--primary);
    --sidebar-item-active: var(--primary-middle);
  }
</style>