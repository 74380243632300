import ApiService from "./api-service";
import CustomStore from 'devextreme/data/custom_store';


async function loadLookupData(key){

  const requestData ={
    method: "GET",
    url: "/lookup",
    params: {id : key}
  };

  try {
    const data = await ApiService.customRequest(requestData);
    return data.data;
  } catch (error) {
    throw new Error(error.message);
  }
}

const LookupStore = new CustomStore({
  loadMode: 'raw',
  load: loadLookupData
});

export { LookupStore };