<template>
  <div ref="app" id="app" style="grid-template-columns: 10% 1fr;">
    <Sidebar id="appNavigation" @toogle="navbarToogle"/>
    <Header id="appHeader" />
    <div id="appContent" class="app-content">
        <slot />
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
export default {
  setup() {
    const app = ref(null);
    const navbarToogle = (e) => {
      if(e.collapsed){
        app.value.style.gridTemplateColumns  = '2.3% 1fr';
      }
      else{
        app.value.style.gridTemplateColumns  = '10% 1fr';
      }
    };
    return { navbarToogle, app };
  },
  components:{
    Sidebar,
    Header
  }
}

import { ref, }  from 'vue'

import Sidebar from '@/components/sidebar/Sidebar.vue'
import Header from '@/components/header/Header'
</script>