import 'devextreme/dist/css/dx.light.css';
import { createApp } from 'vue'
import ApiService from '@/services/api-service'
import App from './App.vue'
import router from './router'
import store from '@/store'
import notify from 'devextreme/ui/notify';

import { library } from "@fortawesome/fontawesome-svg-core";
import { faAngleDoubleLeft, faFile, faArrowCircleDown, faTachometerAlt, faDesktop } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDoubleLeft, faFile, faArrowCircleDown, faTachometerAlt, faDesktop);

ApiService.init(process.env.VUE_APP_API_URL);

console.log(process.env.VUE_APP_API_URL);

const app = createApp(App)
  .use(store)
  .use(router)
  .component("font-awesome-icon", FontAwesomeIcon);

app.provide('notify', notify);

app.mount('#appContainer');
