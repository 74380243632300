import { UserService as auth} from '../services/user-service'
import { createWebHistory, createRouter } from "vue-router";
import simpleLayout from "../layouts/single-card";
import defaultLayout from "../layouts/default-layout"
import Licenses from '../views/Licenses.vue'

function loadView(view) {
    return () => import (/* webpackChunkName: "login" */ `../views/${view}.vue`)
}

console.log(`with a: ${process.env.VUE_APP_WITHOUT_AUTH}`);

const publicRout = false;//process.env.VUE_APP_WITHOUT_AUTH === "true" ? true : false;

const routes = [
    {
        path: "/",
        name: "Dashboard",
        meta: {
            public: publicRout,
            layout: defaultLayout
        },
        component: loadView("Dashboard"),
    },
    {
        path: "/licenses/:id(\\d+)?",
        props: true,
        name: "Licenses",
        meta: {
            public: publicRout,
            layout: defaultLayout
        },
        component: Licenses,
    },
    {
        path: "/users",
        props: true,
        name: "Users",
        meta: {
            public: publicRout,
            layout: defaultLayout
        },
        component: loadView("users"),
    },
    {
        path: "/licenses",
        props: true,
        name: "Licenses",
        meta: {
            public: publicRout,
            layout: defaultLayout
        },
        component: Licenses,
    },

    {
        path: "/license-requests",
        name: "LicenseRequests",
        meta: {
            public: publicRout,
            layout: defaultLayout
        },
        component: loadView("LicenseRequests"),
    },
    {
        path: "/login",
        name: "login",
        meta: {
          public: true,
          onlyWhenLoggedOut: true,
          layout: simpleLayout,
          title: "Sign In"
        },
        component: loadView("login-form")
    },
    // {
    //     path: "/reset-password",
    //     name: "reset-password",
    //     meta: {
    //         public: false,
    //         layout: simpleLayout,
    //         title: "Reset Password",
    //         description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
    //     },
    //     component: loadView("reset-password-form")
    // },
    // {
    //     path: "/create-account",
    //     name: "create-account",
    //     meta: {
    //         public: false,
    //         layout: simpleLayout,
    //         title: "Sign Up"
    //     },
    //     component: loadView("create-account-form"),
    // },
    // {
    //     path: "/:pathMatch(.*)*",
    //     redirect: "/"
    // },
    // {
    //     path: "/:catchAll(.*)",
    //     name: "NotFound",
    //     meta: {
    //         public: true,
    //         layout: emptyLayout,
    //         title: "404"
    //     },
    //     component: loadView("not-found-view")
    // }
];

const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    const isPublic = to.matched.some(record => record.meta.public)
    const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
    const loggedIn = auth.loggedIn;
  
    if (!isPublic && !loggedIn) {
      return next({
        path:'/login',
        query: {redirect: to.fullPath}  // Store the full path to redirect the user to after login
      });
    }
  
    // Do not allow user to visit login page or register page if they are logged in
    if (loggedIn && onlyWhenLoggedOut) {
      return next('/')
    }
  
    next();
  });

export default router;