import axios from 'axios';
import { TokenService } from '@/services/storage-service';
import store from '@/store'

const ApiService = {

  // Stores the 401 interceptor position so that it can be later ejected when needed
  _401interceptor: null,

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    this.mount401Interceptor();
    this.setAuthorizationToken();
  },

  setHeader(headerKey, headerValue) {
    axios.defaults.headers.common[headerKey] = headerValue;
  },

  setAuthorizationToken(token){
    if(!token)
      token = TokenService.getToken();
        
    if(token)
      this.setHeader("Authorization", `Bearer ${token}`);    
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /**
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
  **/
  customRequest(data) {
    return axios(data);
  },

  getData(response){
    return response.data
  },

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use((response) => response,async (error) => {
      console.log('interceptor is working');
      console.log(`error request status ${error.request.status}`);

     
      // If error was not 401 just reject as is
      if(error.request.status != 401)
        throw error

      if(error.config.url.includes('identity/refresh')){
        
        // Refresh token has failed. Logout the user
        store.dispatch('auth/logout')
        throw error

      } else {
        console.log(`error request url not inclded refresh path`);
        // Refresh the access token
        try{
          console.log(`try dispatch auth/refreshToken`);
          //await store.dispatch('auth/refreshToken')
          await store.dispatch('auth/refreshToken')
          // Retry the original request
          return this.customRequest({
              method: error.config.method,
              url: error.config.url,
              data: error.config.data
          })
        } catch (e) {
          console.log(e);    
          // Refresh has failed - reject the original request
          throw error
        
        }
      }

    });
  },

  unmount401Interceptor() {
  }
}

export default ApiService;