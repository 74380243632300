import ApiService from './api-service'
import { TokenService } from './storage-service'
import CustomStore from 'devextreme/data/custom_store';


class AuthenticationError extends Error {
  constructor(errorCode, message) {
      super(message)
      this.name = this.constructor.name
      this.message = message
      this.errorCode = errorCode
  }
}

const UserService = {
  login: async function(email, password) {
    const requestData = {
      method: 'post',
      url: "/identity/login",
      data: {
          email: email,
          password: password
      }
    };

    try {
      const data = await ApiService
                              .customRequest(requestData);
      TokenService.saveToken(data.data.token);
      TokenService.saveRefreshToken(data.data.refreshToken);
      ApiService.setAuthorizationToken(data.data.token);
      
      // NOTE: We haven't covered this yet in our ApiService 
      //       but don't worry about this just yet - I'll come back to it later
      ApiService.mount401Interceptor();

      return {
        ok: true
      }
    } catch (error) {
        // throw new AuthenticationError(error.response.status, error.response.data.detail)
        return {
          ok: false,
          messages: error.response.data.errors
        }
    }
  },
  
  refreshToken: async function() {
    const refreshToken = TokenService.getRefreshToken();
    const token = TokenService.getToken();
    const requestData = {
      method: 'post',
      url: "/identity/refresh/",
      data: {
          token: token,
          refreshToken: refreshToken
      }
    };

    try {
      const data = await ApiService
                              .customRequest(requestData);

      TokenService.saveToken(data.data.token)
      TokenService.saveRefreshToken(data.data.refreshToken)
      
      ApiService.setAuthorizationToken(data.data.token)

      return data.data.access_token
    } catch (error) {
        throw new AuthenticationError(error.response.status, error.response.data.detail)
    }
  },

  createAccount: async function(email, password){
    const requestData = {
      method: 'post',
      url: "/identity/register",
      data: {
          email: email,
          password: password
      }
    };

    try{
      const data = await ApiService
                          .customRequest(requestData);

      TokenService.saveToken(data.data.token);
      TokenService.saveRefreshToken(data.data.refreshToken);
      ApiService.setAuthorizationToken(data.data.token);
      
      // NOTE: We haven't covered this yet in our ApiService 
      //       but don't worry about this just yet - I'll come back to it later
      ApiService.mount401Interceptor();

      return {
        ok: true
      }
    } catch(error){
      // Error 😨
      if (error.response) {
        /*
        * The request was made and the server responded with a
        * status code that falls out of the range of 2xx
        */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          return {
            ok: false,
            messages: error.response.data.errors
          };
      } else if (error.request) {
        /*
        * The request was made but no response was received, `error.request`
        * is an instance of XMLHttpRequest in the browser and an instance
        * of http.ClientRequest in Node.js
        */
        console.log(error);
        console.log(error.request);     
        return {
          ok: false,
          messages: ["Network Error"]
        };
      } else {
        // Something happened in setting up the request and triggered an Error
        console.log('Error', error.message);
        return {
          ok: false,
          messages: [error.message]
        };
      }
    }
  },

  logout(){
    // Remove the token and remove Authorization header from Api Service as well 
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();
    
    // NOTE: Again, we'll cover the 401 Interceptor a bit later. 
    ApiService.unmount401Interceptor();
  },

  getAllRoles: async function(loadOptions){
    let params = {
      pageNumber : Math.ceil(loadOptions["skip"] / loadOptions["take"] + 1),
      pageSize : loadOptions["take"]
    };
    
    const requestData = {
      method: 'GET',
      url: "/roles",
      params: params
    };

    try {
      const { data } = await ApiService.customRequest(requestData);
      return {
         data: data.pagedData,
         totalCount: data.totalCount,
         summary: data.summary,
         groupCount: data.groupCount
       };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  createNewRole: async function(values){
    const requestData = {
      method: 'POST',
      url: "/roles",
      data: values
    };

    try {
      await ApiService.customRequest(requestData);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  updateRole: async function(key, values){
    const patch = [];
    
    Object.entries(values)
      .forEach(function(entry){
        const [entryKey, entryValue] = entry;
        patch.push({
          op: "add",
          path: entryKey,
          value: entryValue
        }); 
      });
    
    const requestData ={
      method: "PATCH",
      url: "/roles",
      params: {
        id: key
      },
      data: patch
    };
    
    try {
      await ApiService.customRequest(requestData);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  deleteRole: async function(key){
    const requestData ={
      method: "DELETE",
      url: "/roles",
      params: {
        id: key
      },
    };

    try {
      await ApiService.customRequest(requestData);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  loadUsers: async function(loadOptions){
    let params = {
      pageNumber : Math.ceil(loadOptions["skip"] / loadOptions["take"] + 1),
      pageSize : loadOptions["take"]
    };

    const requestData = {
      method: 'GET',
      url: "/users",
      params: params
    };

    try {
      const { data } = await ApiService.customRequest(requestData);

      return {
         data: data.pagedData,
         totalCount: data.totalCount,
         summary: data.summary,
         groupCount: data.groupCount
       };
    } catch (error) {
      throw new Error(error.message);
    }
  }

}

Object.defineProperties(UserService, {
  loggedIn: {
    get: function() {
      return !!TokenService.getToken();
    }
  }
});

const RolesStore = new CustomStore({
  key: 'id',
  load: UserService.getAllRoles,
  insert: UserService.createNewRole,
  update: UserService.updateRole,
  remove: UserService.deleteRole
});

const UserStore = new CustomStore({
  key: 'email',
  load: UserService.loadUsers,
  insert: (values) => {
    console.log(`values ${values}`);
  },
  update: (key, value) => {console.log(`${key} ${value}`)},
  remove: (key) => {console.log(key)}
});

export default UserService

export { UserService, RolesStore, UserStore, AuthenticationError }