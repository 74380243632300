<template>
    <DxDataGrid
        ref="licensesDataGrid"
        :selection="{ mode: 'single' }"
        :data-source="licenseStore" 
        :allow-column-reordering="true"
        :column-auto-width="true"
        :allow-column-resizing="true"
        :column-resizing-mode="currentMode"
        :column-min-width="50"
        :hover-state-enabled="true"
        :show-borders="true"
        @row-dbl-click="rowDoubleClick"
        @toolbar-preparing="onToolbarPreparing($event)"
    >
        <DxColumn
        type="buttons"
        :width="110"
        :visible="false"
        caption="Command buttons"
        :show-in-column-chooser="false"
        >
            <DxButton name="edit"/>
            <DxButton name="delete"/>
            <DxButton name="insert"/>
            <DxButton
            hint="Clone"
            icon="repeat"
            :visible="true"
            />
        </DxColumn>
        <DxColumn data-field="id" data-type="number" caption="ID" :visible="false" />
        <DxColumn data-field="firmName" data-type="string" caption="Firm Name" />
        <DxColumn data-field="databaseName" data-type="string" caption="Database Name" />
        <DxColumn data-field="serverName" data-type="string" caption="Server Name" :visible="false" />
        <DxColumn data-field="clientId" data-type="string" caption="Client Identity" :visible="false" />
        <DxColumn data-field="clientIp" data-type="string" caption="Client IP adress" />
        <DxColumn data-field="licenseType" data-type="string" caption="License type" width="75" >
            <DxLookup :data-source="lookupStore" display-expr="name" value-expr="id" />
        </DxColumn>
        <DxColumn data-field="connectionsCount" data-type="number" caption="Connection Count" width="65" />
        <DxColumn data-field="maxListConnectionsCount" data-type="number" caption="Max List Connections Count" width="65" />
        <DxColumn data-field="expiryDate" data-type="datetime" caption="Expiry Date" />
        <DxColumn data-field="activated" data-type="bool" caption="Activated" width="50" />
        <DxColumn data-field="compulsoryIssuance" data-type="bool" caption="Compulsory Issuance" width="50" />
        <DxColumn data-field="installed" data-type="bool" caption="Installed" width="50" />
        <DxColumn data-field="requiresСonfirmation" data-type="bool" caption="Requires Сonfirmation" width="50"/>
        <DxColumn data-field="createAt" data-type="datetime" caption="Create At"  />
        <DxColumn data-field="lastModifyDate" data-type="datetime" caption="Last ModifyDate" />
        <DxColumn data-field="description" data-type="string" caption="Description" :visible="false" />
        <DxColumn data-field="requestFullData" data-type="bool" caption="Request Full Data" :visible="false" />
    
        <DxEditing
            :allow-updating="true"
            :allow-inserting="true"
            :allow-deleting="true"
            :use-icons="true"        
            mode="popup"
            startEditAction="dblClick">
            <DxForm label-location="top">
                <DxItem
                :col-count="2"
                :col-span="2"
                item-type="group"
                caption="License data"
                >
                    <DxItem data-field="firmName" :disabled="false"/>
                    <DxItem data-field="licenseType"/>
                    <DxItem data-field="connectionsCount"/>
                    <DxItem data-field="maxListConnectionsCount"/>
                    <DxItem data-field="expiryDate" />
                    <DxItem data-field="activated" editorType="dxCheckBox"/>
                    <DxItem data-field="compulsoryIssuance" editorType="dxCheckBox"/>
                    <DxItem data-field="installed" editorType="dxCheckBox" />
                    <DxItem data-field="requiresСonfirmation" editorType="dxCheckBox"  />
                    <DxItem data-field="clientIp" :disabled="true"/>
                    <DxItem data-field="serverName" :disabled="true"/>
                    <DxItem data-field="databaseName" :disabled="true"/>
                    <DxItem data-field="requestFullData" editorType="dxCheckBox"/>
                </DxItem>
                <DxItem
                    :col-count="2"
                    :col-span="2"
                    item-type="group"
                    caption="Service data"
                >
                    <DxItem data-field="id" :disabled="true"/>
                    <DxItem data-field="createAt" :disabled="true"/>
                    <DxItem data-field="lastModifyDate" :disabled="true"/>
                </DxItem>
            </DxForm>
            <DxPopup
                :show-title="true"
                :drag-enabled="true"
                :width="768"
                :min-width="350"
                :height="525"
                :min-height="300"
                
                :resize-enabled="true"
                @resize="popupResize"
                title="License info"
            />
        </DxEditing>

        <DxFilterRow :visible="true" />
        <DxSearchPanel :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxLoadPanel :enabled="true"/>
        <DxColumnChooser :enabled="true"/>
        <DxPaging 
            :enabled="true"
            :page-size="viewModel.paging.size"
            :page-index="viewModel.paging.number"
        />
        <DxPager
            :show-page-size-selector="true"
            :allowed-page-sizes="[30, 100, 500, 1000]"
            :show-info="true"
            :show-navigation-buttons="true"
        />
        <DxRemoteOperations
            :filtering = "false"
            :paging = "true"
            :sorting = "false"
            :summary = "false"
            :grouping = "false"
            :group-paging = "false"
        />

    </DxDataGrid>   
</template>


<script>

const pageSettings = {
  size: 30,
  number: 0
};

export default {
    props:{
        id: Number
    },
    setup() {
        const licenseStore = LicenseStore;
        const lookupStore = LookupStore;
        let licensesDataGrid = ref(null);

        const viewModel = {
            paging: pageSettings
        };

        const rowDoubleClick = (row) => {
            licensesDataGrid.value.instance.editRow(row.rowIndex);
        };

        const popupResize = () => {

        }; 

        const onToolbarPreparing = (e) => {
            e.toolbarOptions.items.unshift(
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: "refresh",
                    onClick: refreshDataGrid.bind(this)
                }
            },)
        };

        const refreshDataGrid = () => {
            licensesDataGrid.value.instance.refresh();
        };

        return { licenseStore, lookupStore, viewModel, popupResize, rowDoubleClick, licensesDataGrid, onToolbarPreparing };
    },

    components:{
        DxDataGrid,
        DxFilterRow,
        DxSearchPanel,
        DxGroupPanel,
        DxPager,
        DxPaging,
        DxRemoteOperations,
        DxColumnChooser,
        DxLoadPanel,
        DxColumn,
        DxLookup,
        DxEditing,
        DxForm,
        DxPopup,
        DxItem
    }
}

    import { 
        DxDataGrid,    
        DxFilterRow,
        DxSearchPanel,
        DxGroupPanel,
        DxPager,
        DxPaging,
        DxRemoteOperations,
        DxColumnChooser,
        DxLoadPanel,
        DxColumn,
        DxLookup,
        DxEditing,
        DxForm,
        DxPopup
    } from 'devextreme-vue/data-grid';

    import { DxItem, 
    // DxSimpleItem 
    } from 'devextreme-vue/form';
    
    import { ref } from 'vue'
    import { LicenseStore } from '@/services/license-service';  
    import { LookupStore } from '@/services/lookup-service';  
</script>