import ApiService from "./api-service";
import CustomStore from 'devextreme/data/custom_store';


const LicenseService = {
  loadLicense: async function(loadOptions){
    let params = {
      pageNumber : Math.ceil(loadOptions["skip"] / loadOptions["take"] + 1),
      pageSize : loadOptions["take"]
    };

    const requestData ={
      method: "GET",
      url: "/license",
      params: params
    };

    try {
      const data = await ApiService.customRequest(requestData);

      return {
         data: data.data.pagedData,
         totalCount: data.data.totalCount,
         summary: data.data.summary,
         groupCount: data.data.groupCount
       };
    } catch (error) {
      throw new Error(error.message);
    }
  },


  updateLicense: async function(key, values){
    const pathLicense = [];
    
    Object.entries(values)
      .forEach(function(entry){
        const [entryKey, entryValue] = entry;
        pathLicense.push({
          op: "add",
          path: entryKey,
          value: entryValue
        }); 
      });
    
    // const json = JSON.stringify(pathLicense);

    const requestData ={
      method: "PATCH",
      url: "/license",
      params: {
        id: key
      },
      data: pathLicense
    };
    
    try {
      await ApiService.customRequest(requestData);
    } catch (error) {
      throw new Error(error.message);
    }
  },


  deleteLicense: async function(key){
    const requestData ={
      method: "DELETE",
      url: "/license",
      params: {
        id: key
      },
    };

    try {
      await ApiService.customRequest(requestData);
    } catch (error) {
      throw new Error(error.message);
    }
  } 
}; 

const LicenseStore = new CustomStore({
  key: 'id',
  load: LicenseService.loadLicense,
  insert: (values) => {
    console.log(`values ${values}`);
  },
  update: LicenseService.updateLicense,
  remove: LicenseService.deleteLicense
});

export { LicenseStore }