<template>
      <component
        :is="$route.meta.layout"
      >
        <router-view></router-view>
        <template #footer>
          <footer id="appFooter" >
            <div class="footer-body">

            </div>
          </footer>
        </template>
      </component>
  <!-- <div ref="app" id="app" style="grid-template-columns: 10% 1fr;">
    <Sidebar id="appNavigation" @toogle="navbarToogle"/>
    <Header id="appHeader" />
    <div id="appContent" class="app-content">
        <router-view />
    </div>

  </div> -->
</template>

<script>
export default {
  name: 'App',
  setup(){

  }, 
  provide: {
      user: {
        id: 1, 
        username: "IgorRykov",
        firstName: "Ivanov",
        lastName: "Ivan",
        email: "ivanov.i@bmicro.ru",
        isAdmin: true 
      }
  }
}
</script>

<style>
@import "styles/nullstyle.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
:root{
  --primary: #6b654b;
  --primary-dark: #4a4b2f;
  --primary-light: #B1D4E0;
  --primary-middle: #30321c;
}

html, body {
  font-family: 'Roboto', sans-serif;
}

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: grid;
  grid-template-areas: 
    "header header header"
    "nav appcontent appcontent"
    "footer footer footer";
  grid-template-rows: 60px 1fr 35px;
  /* grid-template-columns: 10% 1fr; */
  grid-gap: 0.5vw;
  flex-direction: column;
  text-align: center;
  height: 100vh;
  width: 100vw;
}

#appNavigation{
  grid-area: nav; 
}

#appHeader{
  grid-area: header;
}

#appContent{
  grid-area: appcontent;
}

#appFooter{
  grid-area: footer;
}

.app-content{
  overflow-y: scroll;
}

.footer-body{
  height: 35px;
  background-color: var(--primary-dark);
}
</style>
